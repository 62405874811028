import HeaderAppyShop, {getComponentStaticProps, HeaderProps} from '../../components/header/headerAppyShop'
import FooterAppyShop from '../../components/footer/footerAppyShop'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {config} from '../../config-merchant'
import {useTranslation} from 'next-i18next';
import Meta from '../../components/meta/meta';
import {appyShopConfig} from '../../config';
import {useEffect, useState} from "react";
import Products from "../../components/catalog/items-list";
import styles from '../../styles/client-ORL.custom.module.scss';
import {useRouter} from "next/router";
import Lottie from 'react-lottie';
import animationData from '../../public/assets/animations/orel-animation-data.json';

export default function Home(props: { headerProps: HeaderProps }) {

    const splashDuration = 3200;
    const fadeDuration = 250;
    let timeoutSplash;

    const {i18n} = useTranslation('common');
    const {t} = useTranslation('common');
    const router = useRouter();
    const [isSplashScreenVisible, setSplashScreenVisible] = useState<boolean>(router.pathname === "/");
    const [isSplashScreenEnded, setSplashScreenEnded] = useState<boolean>(router.pathname !== "/");

    useEffect(() => {
        timeoutSplash = setTimeout(() => {
            setSplashScreenVisible(false);
            setTimeout(() => {
                setSplashScreenEnded(true);
            }, fadeDuration);
        }, splashDuration)

        return () => {
            clearTimeout(timeoutSplash);
        }
    }, [])

    return (
        <>
            <Meta
                title={""}
                description={config.shopSlogan[i18n.language]}
                image={config.merchantDomain + config.defaultMetaImage}
                metaRobots={true}
            />
            {
                !isSplashScreenEnded && <div
                    className={isSplashScreenVisible ? styles['splash-screen'] : `${styles['splash-screen']} ${styles['hide']}`}
                    onClick={() => setSplashScreenEnded(true)}
                >
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid meet'
                            }
                        }}
                        height={"80vh"}
                    />
                </div>
            }
            <HeaderAppyShop activepage={'home'} headerProps={props.headerProps}/>

            <main className={styles['main-contents']}>
                {i18n.language === 'fr' &&
                    <>
                        <p className={styles['citation']}>“{t('HOME_TXT_1_0')}”<span
                            className={styles['sign']}>Aurélie</span>
                        </p>
                        <img src="/assets/images/cover.jpg" className={styles['cover']}/>

                        <p className={styles['citation']}>L’accord
                            des notes
                        </p>

                        <div>
                            <p className={styles['text']}>
                                <span className={styles['orel-label']}>Orel</span> est une marque de parfumerie confidentielle suisse enracinée dans l’héritage culturel malien. La marque met en lumière la riche tradition du continent africain en matière de parfumerie. Les notes harmonieuses des esprits de parfum <span className={styles['orel-label']}>Orel</span> naissent de la mise en musique de matières premières soigneusement sélectionnées assemblées en une symphonie olfactive. Établie à Genève, <span className={styles['orel-label']}>Orel</span> compose avec exigence des parfums de caractère mixtes affranchis des codes de l’industrie pour s’attacher à révéler une personnalité plutôt qu’un genre.
                            </p>
                            <p className={styles['text']}>
                                Comme en musique, <span className={styles['orel-label']}>Orel</span> offre une variation sur un thème. Si l’histoire de sa créatrice en est le point de départ, la puissance évocatrice des fragrances résonne différemment en chacun d’entre nous. Les accords et notes des quatre premiers opus de la collection <span className={styles['orel-label']}>Orel</span> se dévoilent au contact du corps. L’esprit de parfum <span className={styles['orel-label']}>Orel</span> devient ambassadeur votre présence.
                            </p>
                            <p className={styles['text']}>
                                Nommés en hommage aux instruments de musique traditionnels du Mali : <span className={styles['orel-label']}>Imzad</span>, <span className={styles['orel-label']}>Do</span>, <span className={styles['orel-label']}>Kora</span> et <span className={styles['orel-label']}>N’Goni</span> font de chaque personnalité le chef d’orchestre d’un sillage singulier.
                            </p>
                            <p className={styles['citation']}>
                                <span className={styles['orel-label']}>Imzad</span>, <span className={styles['orel-label']}>Do</span>, <span className={styles['orel-label']}>Kora</span> ou <span className={styles['orel-label']}>N’Goni</span> :<br/>quel esprit de parfum capturera votre état d’âme ?
                            </p>
                        </div>
                    </>
                }
                {i18n.language === 'en' &&
                    <>
                        TODO idem en anglais
                    </>
                }

                {/*<p className={styles['img-center']}>
                    <img src="/assets/svg/instrument-3.svg"/>
                </p>*/}

                <div className={styles["home-text3"]}>
                    <p className={styles['citation']}>Notre sélection</p>
                    <div className={styles["home-select-products"]}>
                        <Products
                            nbProducts={4}
                            activeCategory={undefined}
                            activeSorting={"RELEVANCE"}
                            researchQuery={""}
                            setNbProducts={() => {/*ignored*/
                            }}
                            activeTags={[30]}
                        />
                    </div>
                    <a href={"/catalog"}>
                        <div className={styles["button"]}>Voir le catalogue complet</div>
                    </a>
                </div>

            </main>

            <FooterAppyShop/>
        </>

    )
}

export const getStaticProps = async ({locale}) => {
    if (config.menu.some(item => item.path === 'home') || config.footerLink.some(item => item.url === '/home')) {
        return {
            props: {
                ...await serverSideTranslations(locale, ['common']),
                headerProps: await getComponentStaticProps(locale)
            },
            revalidate: appyShopConfig.revalidateData
        }
    } else {
        return {
            notFound: true
        }
    }
}



